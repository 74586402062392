
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleOrderList from "./modules/order"
import moduleChatList from "./modules/chat"
import moduleNotification from './modules/notification'

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
        chatList:moduleChatList,
        orderList:moduleOrderList,
        notification:moduleNotification
    },
    strict: process.env.NODE_ENV !== 'production'
})

